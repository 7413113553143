import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(
    @Inject(DOCUMENT) private dom: Document,
    private titleSvc: Title,
    private metaSvc: Meta
  ) {}

  updateTitle(title: string): void {
    if (title) {
      this.titleSvc.setTitle(title);
    } else {
      this.titleSvc.setTitle('SIEL.AI');
    }
  }

  updateDescription(content: string): void {
    if (content) {
      this.metaSvc.updateTag({ name: 'description', content });
    }
  }

  updateSeoFromRoute(metadata: Data): void {
    this.updateTitle(metadata['title']);
    this.updateDescription(metadata['description']);
  }

  createCanonicalLink(url: string): void {
    if (url) {
      let link: HTMLLinkElement = this.dom.createElement('link');
      if (!link) {
        link = this.dom.createElement('link');
        link.setAttribute('rel', 'canonical');
        this.dom.head.appendChild(link);
      }
      link.setAttribute('href', url);
    }
  }
}
