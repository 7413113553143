  <section>
    <div class="d-flex flex-column w-100">
      <div id="what" class="w-100 section-1 py-4 py-md-5 px-md-0">
        <div class="container">
          <div class="d-flex flex-column flex-md-row w-100 mb-2 mb-md-5">
            <h2 class="section-title-2 col-12 col-md-3 pb-3 pb-md-0">What</h2>
            <p class="title text-white col-12 col-md-9">A community of seasoned professionals with deep knowledge of HyperAutomation and Business Continuous Improvement.</p>
          </div>
          <div class="d-flex flex-column flex-md-row w-100 gx-md-4 justify-content-between mt-0 mt-md-3">
              <div class="d-flex flex-column col-12 col-md-3">
                  <div class="icon"><img src="assets/images/svg/icon-1.svg" alt="Icon 1"></div>
                  <p class="text text-white mt-2 mt-md-4">We are passionate about helping people and enterprises achieve growth and navigate the ever-changing landscape of technology. By turning buzzwords like 'AI' into practical tools, we empower humans to reach their full potential and make meaningful contributions to company growth.</p>
              </div>
              <div class="d-flex flex-column col-12 col-md-3">
                  <div class="icon"><img src="assets/images/svg/icon-2.svg" alt="Icon 2"></div>
                  <p class="text text-white mt-2 mt-md-4">We offer bespoke services to help you select and implement the best tools to accelerate your business. By combining your team's skills with our refined best practices and frameworks, we ensure your business is ready to thrive in a competitive landscape.</p>
              </div>
              <div class="d-flex flex-column col-12 col-md-3">
                  <div class="icon"><img src="assets/images/svg/icon-3.svg" alt="Icon 3"></div>
                  <p class="text text-white mt-2 mt-md-4">Our consulting and delivery services are led by specialists with proven expertise in business processes and advanced technologies, backed by a track record of success.</p>
              </div>
          </div>
        </div>
      </div>
      <div class="w-100 section-2">
        <div class="container" id="why">
          <div class="content-section d-flex flex-column flex-md-row py-4 py-md-5 px-md-0">
              <h2 class="section-title col-12 col-md-3">Why</h2>
              <div class="col-12 col-md-9">
                <div class="col-md-11">
                  <h3 class="title mb-4">A company's growth is strictly dependent on the people that choose to work there.</h3>
                </div>
                <div class="col-md-8">
                  <p class="text">Businesses don't need automation so they can complete more processes in the same amount of time, but rather to free the minds of their employees from mundane and repetitive tasks so they can focus on bigger things that add more value.</p>
                </div>
                <div class="col-md-8">
                  <p class="text">By adopting intelligent automation, companies not only increase operational efficiency but also cultivate a work environment where creativity and innovation thrive. Employees can dedicate more of their time to problem-solving, strategic planning, and driving the business forward.
                    This shift in focus fosters a mindset of ongoing innovation and development, enabling the company and its employees to adapt to change, embrace new opportunities, and stay ahead in an increasingly competitive landscape.</p>
                </div>

              </div>
          </div>
          <!-- <div class="svg-container-1">
            <img src="assets/images/svg/why-1.svg" alt="Decorative SVG Why 1">
          </div> -->
          <div class="svg-container-2">
            <img src="assets/images/svg/why-2.svg" alt="Decorative SVG Why 2">
          </div>
        </div>
      </div>
      <app-parteners></app-parteners>
      <div class="w-100">
        <div class="why-card py-4 py-md-5">
          <div class="container">
            <div class="content col-md-9 d-flex flex-column justify-content-center h-100 px-md-0">
              <h1 class="title">We are SIEL.AI, a company founded by experienced industry experts with one thought in mind – to grow other companies by using AI to <span class="highlight">make humans better</span>.</h1>
              <div class="col-md-9">
                <p class="text pb-2">SIEL.AI uses AI to make jobs more enjoyable and the humans that perform them, happier.</p>
                <p class="text">And happier, more dedicated humans are more likely to transform a company into a market leader, not a follower.</p>
                <p class="text">By leveraging advanced AI capabilities, we help create a culture of continuous improvement, where employees are empowered to innovate, collaborate, and push the boundaries of what’s possible. This approach not only enhances productivity but also nurtures a dynamic, future-ready workforce that is better equipped to tackle evolving business challenges.</p>
              </div>
            </div>
            <div class="svg-container-3">
              <img src="assets/images/svg/why-3.svg" alt="Decorative SVG Why 3">
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
