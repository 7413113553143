<div class="container-fluid px-0">
  <section #section class="py-1 py-md-3 py-lg-4">
    <div class="container">
      <div class="d-flex flex-column flex-md-row my-2">
        <h1 class="section-title title">Blogs</h1>
      </div>
      <div class="d-flex flex-row justify-content-start w-100">
        <div class="purple-line"></div>
      </div>
    </div>
  </section>
  <section class="container fade-down-up">
    <div class="card-list">
      <app-blog-card *ngFor="let blog of blogs" [blog]="blog"></app-blog-card>
    </div>
  </section>
  <app-contact-section [page]="'Blogs'"></app-contact-section>
</div>
