import { Component, ElementRef, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-technology',
  templateUrl: './technology.component.html',
  styleUrls: ['./technology.component.scss'],
})
export class TechnologyComponent {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;


  cloudProcesses =[
    {
      "number": "Cloud Strategy and Migration",
      "text": "We assess your existing infrastructure and help you plan a smooth migration to the cloud, whether it’s public, private, or hybrid. We ensure your systems are optimized for performance, cost-efficiency, and security."
    },
    {
      "number": "Cloud-Native Development",
      "text": "Build and deploy applications directly in the cloud for faster scalability and greater agility, enabling you to respond to market changes in real-time."
    },
    {
      "number": "Cloud Automation",
      "text": "Automate infrastructure management, scaling, and resource optimization using cloud-native tools, ensuring that your cloud environment is as efficient as possible."
    },
    {
      "number": "Cloud Staff Augmentation",
      "text": "Our team of cloud experts can step in to augment your internal team, providing guidance, expertise, or additional capacity to help manage and optimize your cloud operations."
    }
  ];
  constructor(private headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }
}
