<div class="testimonial-section">
<div class="container py-4">
  <section class="d-flex flex-column">
    <h2 class="section-title">Testimonials</h2>
    <div class="h-100 testimonials-info">
      <swiper-container init="false" appSwiper #testiomonialSwiper [config]="swiperConfig">
        <swiper-slide *ngFor="let testimonial of testimonials">
            <div class="d-flex flex-column align-items-start align-items-md-center justify-content-start justify-content-md-center flex-md-row my-2 my-md-4">
              <div class="col-lg-3 col-md-6 col-sm-8">
                <div class="rounded-12 overflow-hidden testimonial-img">
                  <img [src]="testimonial.image" class="w-100" [alt]="testimonial.author">
                </div>
              </div>
              <div class="d-flex flex-column justify-content-center mt-2 mt-md-0 col-12 col-lg-7 col-md-6 ms-0 ms-md-3">
                  <div class="flex-row pt-3 pt-md-0">
                    <h2 class="text">{{ testimonial.description }}</h2>
                  </div>
                  <div class="flex-row">
                    <p class="author">{{ testimonial.author }}</p>
                    <p class="company">{{ testimonial.company }}</p>
                  </div>
              </div>
            </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <div class="d-flex flex-row justify-content-center w-100">
      <div class="purple-line"></div>
    </div>
    <div class="svg-container-testimonial-1">
      <img src="assets/images/svg/testimonial-1.svg" alt="Decorative SVG Testimonial 1">
    </div>
  </section>
</div>
</div>
