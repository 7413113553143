<div class="container-fluid fade-down-up">
  <section #section class="card">
    <div class="container py-3 py-md-3 py-lg-4">
      <div class="d-flex flex-column flex-md-row my-2">
        <h1 class="section-title text-whit title">AutomationX</h1>
      </div>
      <div class="d-flex flex-row justify-content-start w-100">
        <div class="purple-line white-line"></div>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center mt-4">
        <div class="subtitle-container">
          <!-- <p class="section-subtitle subtitle text-white">
            Introducing AutomationX—the next-generation platform from SIEL AI, designed to deliver Automation as a Service (AaaS) for businesses looking to accelerate growth, improve efficiency, and drive innovation. AutomationX is a comprehensive product that enables companies to seamlessly automate key processes across departments with out-of-the-box (OOTB) solutions tailored to solve industry-specific challenges.
          </p> -->
         <p class="section-subtitle subtitle text-white">
         SIEL AI's cutting-edge platform offering Automation as a Service (AaaS). Built for businesses looking to accelerate growth and boost efficiency, AutomationX delivers out-of-the-box automation solutions that seamlessly integrate into your ecosystem, driving impact from day one.
        </p>
        </div>
      </div>
      <div class="parent-container position-relative mt-4 my-3 my-lg-5">
        <div class="grid-container">
          <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
            <h3>Mission</h3>
            <p class="text">
              Our mission is to make advanced automation and AI accessible to every business. We empower organizations to focus on strategic growth by handling routine tasks through plug-and-play solutions. By providing out-of-the-box automation, SIEL AI enables companies to improve efficiency, boost productivity, and drive innovation with minimal setup and maximum impact.
            </p>
          </div>
          <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
            <h3>Vision</h3>
            <p class="text">
              We envision a world where businesses operate effortlessly through intelligent automation. AutomationX is more than a service—it’s a trusted, ever-evolving partner that grows with your business. Our vision is to simplify the adoption of AI and automation, allowing companies of all sizes to stay agile, resilient, and competitive in a fast-paced digital landscape.
            </p>
          </div>
        </div>
        <div class="card-secondary pt-3 mt-2 pt-lg-5 pb-3 px-0 px-md-3">
          <div *ngFor="let chunk of processesChunks" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
            <div class="d-flex flex-row align-items-center" *ngFor="let procesut of chunk; let last = last">
              <p class="text-bold text-center">{{ procesut.title }}</p>
              <p *ngIf="!last" class="dot-divider"></p>
            </div>
          </div>
        </div>
      </div>
     
    </div>
  </section>

  <section class="container full-mobile-container">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column section-2 px-2 py-4 p-md-4 my-4">
        <div class="d-flex flex-column left-section-2">
          <h2 class="secondary-title">Key Areas of Focus</h2>
          <p class="text-bold text-white">
            SIEL AI’s out-of-the-box solutions are designed for immediate impact. These pre-built automation modules integrate seamlessly, enabling you to optimize core business functions from day one.
          </p>
  
          <h3 class="secondary-subtitle">
            Revenue Operations (RevOps)
          </h3>
          <p class="text text-white">
            Unlock revenue potential with pre-configured workflows and real-time analytics, aligning sales and marketing for peak efficiency.
          </p>
  
          <h3 class="secondary-subtitle">
            Financial Operations (FinOps)
          </h3>
          <p class="text text-white">
            Automate critical finance functions like reporting and budgeting with OOTB modules, freeing your team to focus on growth and strategy.
          </p>
  
          <h3 class="secondary-subtitle">
            M&A Acceleration
          </h3>
          <p class="text text-white">
            Leverage streamlined, pre-built integrations for a faster, smoother merger process that aligns systems and minimizes disruption.
          </p>
  
          <h3 class="secondary-subtitle">
            Data & Systems Integration
          </h3>
          <p class="text text-white">
            Achieve seamless data flow and real-time access with ready-to-deploy integration modules, ensuring unified insights across your ecosystem.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="container processes-list mt-2 mt-md-4 pb-4">
    <h2 class="section-title processes-title">Effortless Automation for Any Business</h2>
    <p class="text">
      AutomationX by SIEL AI is designed for businesses of all sizes to easily adopt and benefit from intelligent automation without the complexity. With out-of-the-box solutions that are ready to deploy, AutomationX enables companies to quickly enhance efficiency, reduce manual effort, and start seeing the advantages of AI immediately. Whether you’re looking for a targeted solution or planning to scale over time, AutomationX adapts to your needs, making it easy to expand capabilities as your business grows—no lengthy implementations or high costs required.
    </p>
    <div class="d-flex flex-column gap-30 flex-lg-row w-100">
      <div class="d-flex flex-column process-card">
        <div *ngFor="let process of processes | slice: 0:4; let last = last">
          <div class="d-flex flex-row align-items-center gap-30 process">
            <p class="d-flex flex-row align-items-center justify-content-center process-number">{{ process.number }}</p>
            <p class="text">{{ process.text }}</p>
          </div>
          <div class="d-flex flex-row my-2 w-100">
            <div class="purple-line w-75"></div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column process-card">
        <div *ngFor="let process of processes | slice: 4:7; let last = last">
          <div class="d-flex flex-row align-items-center gap-30 process">
            <p class="d-flex flex-row align-items-center justify-content-center process-number">{{ process.number }}</p>
            <p class="text">{{ process.text }}</p>
          </div>
          <div class="d-flex flex-row my-2 w-100">
            <div class="purple-line w-75"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="decorative-2">
      <img src="assets/images/svg/processes-2.png" alt="Decorative PNG Processes 2">
    </div>
</section>


  <!-- <app-contact-section [page]="'Processes Optimization'"></app-contact-section> -->
</div>
