  <div class="container py-4 py-md-5">
    <section>
      <div class="d-flex mb-4">
        <h2 class="section-title">Who</h2>
      </div>
      <swiper-container init="false" appSwiper #teamSwiper [config]="swiperConfig">
        <swiper-slide *ngFor="let member of teamMembers">
          <div class="d-flex flex-row">
            <div class="member-img"
              [style.background-image]="'url(' + member.image + ')'"
              [style.backgroundSize]="'cover'"
              [style.backgroundPosition]="'top center'"
            >
              <div class="d-flex flex-column w-100 justify-content-end member-content">
                <p class="member-name">{{ member.name }}</p>
                <p class="member-position">{{ member.position }}</p>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <div class="d-flex w-100 justify-content-center align-items-center mt-1 mt-md-3 team-read-more">
        <button type="button" class="btn btn-lg btn-success" [routerLink]="['/team']">READ MORE</button>
      </div>
    </section>
  </div>
