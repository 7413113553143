import { CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { BannerComponent } from './home/banner/banner.component';
import { Mainpagerow2Component } from './mainpagerow2/mainpagerow2.component';
import { Mainpagerow4Component } from './mainpagerow4/mainpagerow4.component';
import { StickyMenuComponent } from './sticky-menu/sticky-menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { HttpClientModule } from '@angular/common/http';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { AppRoutingModule } from './app-routing.module';
import { ScrollService } from './services/scroll.service';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { NewsletterPostComponent } from './newsletter-post/newsletter-post.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { BlogCardComponent } from './blog-card/blog-card.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { SeoService } from './services/seo.service';
import { CookieConfigService } from './services/cookie-consent.service';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { PartenersComponent } from './parteners/parteners.component';
import { TeamComponent } from './team/team.component';
import { HomeDetailsComponent } from './home-details/home-details.component';
import { ContactComponent } from './contact/contact.component';
import { TeamPageComponent } from './team-page/team-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { register } from 'swiper/element/bundle';
import { SwiperDirective } from './directive/swiper';
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { ContactSectionComponent } from './contact-section/contact-section.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PeopleStrategyComponent } from './people-strategy/people-strategy.component';
import { ProcessOptimizationComponent } from './process-optimization/process-optimization.component';
import { DataStrategyComponent } from './data-strategy/data-strategy.component';
import { TechnologyComponent } from './technology/technology.component';
import { ProcessOptimizationServicesCardComponent } from './process-optimization-services-card/process-optimization-services-card.component'
import {AutomationAsAServiceComponent } from './automation-as-a-service/automation-as-a-service.component'

register();
@NgModule({
  declarations: [
    AppComponent,
    BannerComponent,
    Mainpagerow2Component,
    Mainpagerow4Component,
    StickyMenuComponent,
    NavbarComponent,
    HomeComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    NewsletterComponent,
    NewsletterPostComponent,
    SubscribeComponent,
    BlogCardComponent,
    ProcessOptimizationServicesCardComponent,
    AutomationAsAServiceComponent,
    TestimonialsComponent,
    PartenersComponent,
    TeamComponent,
    HomeDetailsComponent,
    ContactComponent,
    TeamPageComponent,
    SwiperDirective,
    ContactSectionComponent,
    BlogsComponent,
    BlogPostComponent,
    PeopleStrategyComponent,
    ProcessOptimizationComponent,
    TechnologyComponent,
    DataStrategyComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    RecaptchaV3Module,
    NgcCookieConsentModule.forRoot(
      CookieConfigService.getDefaultCookieConstentConfig()
    ),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    ScrollService,
    GoogleAnalyticsService,
    SeoService,
    CookieConfigService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptchaSiteKey,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
