import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'process-optimization-services-card',
  templateUrl: './process-optimization-services-card.component.html',
  styleUrls: ['./process-optimization-services-card.component.scss'],
})
export class ProcessOptimizationServicesCardComponent implements OnInit {
  @Input() processOptimizationService: any;
  constructor() {}

  ngOnInit(): void {}

  onImageError(event: Event) {
    const imgElement = event.target as HTMLImageElement;
    imgElement.src = 'assets/images/logo.png';
  }
}
