import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  baseURL = `${environment.apiUrl}/settings`;
  constructor(private http: HttpClient) {}

  getPrivacyPolicy(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/privacy-policy`);
  }

  getTermsAndConditions(): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/terms-and-conditions`);
  }
}
