import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyResolver } from './resolver/privacy-policy.resolver';
import { TermsAndConditionsResolver } from './resolver/terms-and-conditions.resolver';
import { BlogPostResolver } from './resolver/blog-post.resolver';
import { metadata } from './constants/metadata.constants';
import { TeamPageComponent } from './team-page/team-page.component';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogPostComponent } from './blog-post/blog-post.component';
import { BlogsResolver } from './resolver/blog.resolver';
import { PeopleStrategyComponent } from './people-strategy/people-strategy.component';
import { ProcessOptimizationComponent } from './process-optimization/process-optimization.component';
import { DataStrategyComponent } from './data-strategy/data-strategy.component';
import { TechnologyComponent } from './technology/technology.component';
import { AutomationAsAServiceComponent } from './automation-as-a-service/automation-as-a-service.component';

export const routes: Routes = [
  { path: '', component: HomeComponent, data: metadata.home },
  {
    path: 'people-strategy',
    component: PeopleStrategyComponent,
    data: metadata.peopleStrategy,
  },
  {
    path: 'process-optimization',
    component: ProcessOptimizationComponent,
    data: metadata.processOptimization,
  },
  {
    path: 'technology',
    component: TechnologyComponent,
    data: metadata.technology,
  },
  {
    path: 'automation-as-a-service',
    component: AutomationAsAServiceComponent,
    data: metadata.automationasaservice,
  },
  {
    path: 'data-strategy',
    component: DataStrategyComponent,
    data: metadata.dataStrategy,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    resolve: { policy: PrivacyPolicyResolver },
    data: metadata.privacyPolicy,
  },
  {
    path: 'blogs',
    component: BlogsComponent,
    resolve: { blogs: BlogsResolver },
    data: metadata.blogs,
  },
  {
    path: 'blogs/:id',
    component: BlogPostComponent,
    resolve: { blog: BlogPostResolver },
    data: metadata.blogs,
  },
  {
    path: 'terms-and-conditions',
    component: TermsAndConditionsComponent,
    resolve: {
      terms: TermsAndConditionsResolver,
    },
    data: metadata.termsAndConditions,
  },
  { path: 'team', component: TeamPageComponent, data: metadata.team },
  { path: 'contact', component: ContactComponent, data: metadata.contact },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
