
<div class="container-fluid px-0">
  <section #section class="blog-info">
    <div class="container px-0 d-flex flex-column py-3 justify-content-center">
      <div class="d-flex flex-row blog-info-content">
        <p>{{blog.createdAt  | date: 'MM/dd/yy' }}</p>
        <p>BLOG</p>
        <p>{{blog.readTime}} min</p>
      </div>
      <div class="blog-title">
        <p>{{blog.title}}</p>
      </div>
      <div class="blog-subtitle">
        <p>{{blog.subtitle}}</p>
      </div>
    </div>
    <div class="decorative">
      <img src="assets/images/svg/ai-3.png" alt="Decorative PNG Blog 3">
    </div>
  </section>
  <section class="container px-0 py-4 d-flex flex-column">
    <div class="d-flex flex-column flex-md-row gap-30">
      <div class="w-100 blog-image d-flex" [ngStyle]="{'background-image': 'url(' + (blog.image || 'assets/images/logo.png') + ')'}" style="background-size: cover; background-position: center; background-repeat: no-repeat; width: 100%;" [routerLink]="['/blogs', blog.id]"></div>
      <div class="d-flex flex-row align-items-center blog-abstract">
        <p>{{blog.abstract}}</p>
      </div>
    </div>
    <div class="d-flex flex-column flex-md-row blog-paragraphs pt-3">
      <div *ngFor="let paragraph of blog.paragraphs">
        <p class="blog-paragraph" [innerHTML]="paragraph"></p>
      </div>
    </div>
  </section>
  <section class="px-0 px-md-3 d-flex flex-column">
    <div class="d-flex flex-row justify-content-center image-divider"></div>
  </section>
  <section class="container px-0 py-3 blog-content">
    <p [innerHTML]="blog.content"></p>
    <div *ngIf="blog.withPDF && !isSent" class="w-100 d-flex flex-row align-items-center justify-content-center mt-3">
      <div class="w-100 d-flex flex-column align-items-center">
        <form [formGroup]="sendForm" (ngSubmit)="sendBlogPdf(blog.id)">
          <div class="pdf-input">
              <input type="email" class="form-control" formControlName="email"  placeholder="E-mail" aria-label="E-mail">
              <button class="btn btn-subscribe" type="submit">Send whitepaper</button>
          </div>
        </form>
        <div [style.visibility]="sendForm.get('email')?.invalid && (sendForm.get('email')?.dirty || sendForm.get('email')?.touched) ? 'visible': 'hidden'">
          <small *ngIf="sendForm.get('email')?.errors?.['required']" class="error">Email is required.</small>
          <small *ngIf="sendForm.get('email')?.errors?.['email']" class="error">Invalid email address.</small>
        </div>
        <div *ngIf="apiError">
          <small class="error">{{apiError}}</small>
        </div>
      </div>
    </div>
    <div *ngIf="blog.withPDF && isSent" class="w-100 d-flex flex-row align-items-center justify-content-center mt-3">
      <div class="w-100 d-flex flex-column align-items-center">
        <p class="pdf-success mb-3">Whitepaper sent! Check your inbox.</p>
      </div>
    </div>
  </section>
  <section class="container px-0 py-2 d-flex flex-column">
    <div class="purple-line mb-3"></div>
    <div class="d-flex flex-column flex-md-row">
      <h1 class="section-title title associated-blogs">Related blogs</h1>
    </div>
    <div class="card-list">
      <app-blog-card  *ngFor="let blog of relatedBlogs" [blog]="blog"></app-blog-card>
    </div>
  </section>
  <app-contact-section [page]="blog.title"></app-contact-section>
</div>
