<div class="container-fluid fade-down-up">
  <!-- <section #section class="section-1 py-1 py-md-3 py-lg-4">
    <div class="container">
      <div class="d-flex flex-column flex-md-row my-2">
        <h1 class="section-title title">Technology</h1>
      </div>
      <div class="d-flex flex-row justify-content-start w-100">
        <div class="purple-line"></div>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center mt-4">
        <div class="left-section-1 d-flex justify-content-start justify-content-md-center">
          <h1 class="primary-title">Artificial Intelligence</h1>
        </div>
        <div class="right-section-1 px-0 px-md-3">
          <p class="text-bold">Artificial Intelligence offers a multitude uses for businesses, particularly in the domain of automation. When integrated with Robotic Process Automation (RPA), AI can do so much more than handle routine and repetitive tasks.</p>
          <p class="text">When it comes to AI, two key subsets - Machine Learning (ML) and Deep Learning - are particularly relevant to automation.</p>
          <p class="text">Machine Learning uses data and algorithms to mirror the human learning process, steadily refining its accuracy.</p>
          <p class="text">Deep Learning, on the other hand, employs artificial neural networks that replicate how our brains process information.</p>
          <p class="text">Both Machine Learning and Deep Learning excel in identifying patterns based on historical data and applying these insights to new scenarios. These systems 'learn' through experience and training and will continually reduce errors to achieve the desired outcomes, helping your business unlock new levels of efficiency and insight.</p>
        </div>
      </div>
    </div>
  </section> -->
  <section #section class="section-1 py-1 py-md-3 py-lg-4">
    <div class="container">
      <div class="d-flex flex-column flex-md-row my-2">
        <h1 class="section-title title">Technology</h1>
      </div>
      <div class="d-flex flex-row justify-content-start w-100">
        <div class="purple-line"></div>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center mt-4">
        <div class="left-section-1 d-flex justify-content-start justify-content-md-center">
          <h1 class="primary-title">Unlock new business opportunities.</h1>
        </div>
        <div class="right-section-1 px-0 px-md-3">
          <p class="text-bold">At SIEL AI, we act as a trusted advisor, helping companies navigate the complex world of technology with confidence. Our expertise spans a broad range of cutting-edge technologies, including Artificial Intelligence, Intelligent Automation, and Cloud solutions, all aligned with our Automation X approach.</p>
          <p class="text">Whether you need strategic guidance, hands-on implementation, or staff augmentation to boost your team’s capabilities, we’re here to help every step of the way.</p>
        </div>
      </div>
    </div>
  </section>

  <!-- <section class="container full-mobile-container">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column section-2 px-2 py-4 p-md-4 my-4">
        <div class="d-flex flex-column left-section-2">
          <h2 class="secondary-title">Conversational AI</h2>
          <p class="text-bold text-white">Conversational AI is a technology that generates human-like interactions between computers and humans. A blend of machine learning, natural language processing (NLP), and speech recognition enables machines to understand, process, and respond to human language in a natural and intuitive way. Conversational AI is a transformative technology best suited for customer and employee experience.</p>
          <h3 class="secondary-subtitle">24/7 Availability for a variety of tasks</h3>
          <p class="text text-white">Conversational AI solutions ensure your customers or employees have access to support anytime. We develop solutions of all sizes, integrating chatbots with software robots performing actions ranging from simple to highly complex.</p>
          <h3 class="secondary-subtitle">Personalized Interactions for Efficient Problem</h3>
          <p class="text text-white"> Conversations are tailored based on history, preferences and detail, for more meaningful and effective engagements. Inquiries and issues are resolved quickly, reducing cycle times and improving overall service quality.</p>
        </div>
      </div>
    </div>
    <div class="decorative-1">
      <img src="assets/images/svg/ai-2.png" alt="Decorative PNG AI 2">
    </div>
  </section> -->

  <section class="container full-mobile-container">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column section-2 px-2 py-4 p-md-4 my-4">
        <div class="d-flex flex-column left-section-2">
          <h2 class="secondary-title">Artificial Intelligence (AI)</h2>
          <p class="text-bold text-white">Artificial Intelligence is more than just a tool—it's a powerful enabler of innovation and transformation. At SIEL AI, we specialize in cutting-edge AI solutions, including Agentic AI, Custom Machine Learning models, and Generative AI, helping businesses create smarter, more adaptive systems.</p>

          <h3 class="secondary-subtitle">Agentic AI: Autonomous Systems for Dynamic Decision-Making</h3>
          <p class="text text-white">Agentic AI refers to AI systems capable of making autonomous decisions based on real-time data and predefined goals. These systems can analyze complex environments, adapt to new situations, and execute actions without human intervention, ensuring dynamic decision-making in rapidly changing scenarios.</p>

          <h3 class="secondary-subtitle">Custom Machine Learning (ML): Tailored AI Solutions for Your Business</h3>
          <p class="text text-white">No two businesses are the same, and neither are their AI needs. At SIEL AI, we develop Custom ML models designed to address specific challenges and opportunities within your organization. Whether it's predictive analytics, optimization, or automation, our tailored ML models ensure you're getting the most out of your data.</p>

          <h3 class="secondary-subtitle">Generative AI: Creating New Possibilities</h3>
          <p class="text text-white">Generative AI goes beyond automating tasks; it creates entirely new content, insights, and solutions. Leveraging powerful models like GPT and other neural networks, we help businesses explore innovative applications such as content generation, design, and creative problem-solving.</p>

          <h3 class="secondary-subtitle">AI Solutions Built for Your Future</h3>
          <p class="text text-white">Our AI capabilities are designed to evolve with your business. Whether you're looking to enhance customer experiences, optimize operations, or develop new products and services, SIEL AI is here to ensure your AI strategy is future-proof and scalable.</p>
        </div>
      </div>
    </div>
    <div class="decorative-1">
      <img src="assets/images/svg/ai-2.png" alt="Decorative PNG AI 2">
    </div>
  </section>

  <section class="container my-4 my-md-5 pb-3 pb-md-4">
    <div class="d-flex flex-column left-section-2">
      <h2 class="primary-title pt-2">Intelligent Automation</h2>
      <p class="text-bold">Intelligent automation isn’t just about automating tasks; it’s about creating smarter workflows that transform how businesses operate. At SIEL AI, we combine Robotic Process Automation (RPA) with Low-Code/No-Code platforms to enable rapid deployment of automation solutions that empower your team and reduce reliance on traditional IT development.</p>

      <h3 class="primary-subtitle pt-2">RPA Strategy and Implementation:</h3>
      <p class="text pt-1">Automate repetitive tasks to free up valuable human resources for strategic work. From process discovery to pilot projects and full-scale deployment, we help you integrate RPA with minimal disruption to your operations.</p>

      <h3 class="primary-subtitle pt-2">Low-Code/No-Code Solutions:</h3>
      <p class="text pt-1">We deploy user-friendly automation platforms that allow non-technical teams to create and implement workflows, increasing agility and productivity across departments while minimizing the need for IT intervention.</p>

      <h3 class="primary-subtitle pt-2">Hyperautomation:</h3>
      <p class="text pt-1">Go beyond individual automations by combining RPA, AI, and advanced analytics to create end-to-end automated processes that span your entire enterprise, ensuring efficiency at scale.</p>

      <h3 class="primary-subtitle pt-2">Automation Staff Augmentation:</h3>
      <p class="text pt-1">From RPA developers to business analysts, our staff augmentation services ensure you have the right talent in place to support and scale your automation initiatives effectively.</p>
    </div>
    <div class="decorative-2">
      <img src="assets/images/svg/ai-3.png" alt="Decorative PNG AI 3">
    </div>
  </section>


<!--
  <section class="container container-business-section my-4 my-md-5 py-3 py-md-4 px-3">
    <p class="text text-bold">At SIEL.AI, we deliver comprehensive automation solutions tailored to your unique business needs. Our Intelligent Automation services empower your teams to focus on high-value work, while RPA and Low Code/No Code platforms handle the routine.</p>
    <p class="text text-bold">Interested in discovering how automation can unlock new efficiencies in your organization? Schedule a meeting with us to learn how we can help you deploy a digital workforce to complement and enhance your human team.</p>
  </section>
   -->


  <!-- <section class="container my-4 my-md-5 pb-3 pb-md-4">
    <div class="d-flex flex-column left-section-2">
      <h2 class="primary-title">Robotic process automation (RPA):</h2>
      <p class="text-bold">Software robots (agents) that can perform routine business tasks by mimicking human interactions with applications. RPA can complete entire processes with little human intervention.</p>
      <h3 class="primary-subtitle">Intelligent automation (IA):</h3>
      <p class="text">Cognitive technologies that can perform human-like tasks, such as recognizing handwriting, natural language processing, and automating non-routine tasks. IA can be combined with RPA and analytics to create intelligent automation solutions.</p>
      <h3 class="primary-subtitle">Agentic Process Automaton (APA):</h3>
      <p class="text">APA is a new approach to automation that uses intelligent agents to automate complex tasks and workflows. These agents use AI techniques like machine learning, natural language processing, and cognitive computing to perceive their environment and take actions to achieve specific goals. Agentic automation can be self-sufficient, learning from interactions and making decisions without constant human oversight.</p>
    </div>
    <div class="decorative-2">
      <img src="assets/images/svg/ai-3.png" alt="Decorative PNG AI 3">
    </div>
  </section>
  <!-- <section class="container container-business-section my-4 my-md-5 py-3 py-md-4 px-3">
      <p class="text text-bold">At SIEL.AI, we can implement a wide variety of AI solutions, but most importantly, we analyze your business and we tailor AI solutions that respond to the specific needs of your business.</p>
      <p class="text text-bold">If you're interested in how your business can become more efficient, skip the technical part and schedule a meeting with us to see how we can help your human workforce become more efficient with the help of digital workforce. </p>
  </section>
-->
  <app-contact-section [page]="'Technology'"></app-contact-section>
</div>
