import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../services/contact.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Component({
  selector: 'app-contact-section',
  templateUrl: './contact-section.component.html',
  styleUrls: ['./contact-section.component.scss'],
})
export class ContactSectionComponent {
  @Input() page: string = '';
  contactForm: FormGroup;
  submitted = false;
  contactSended: boolean = false;
  apiError: string | null = null;

  constructor(
    private fb: FormBuilder,
    private contactService: ContactService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.contactForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      details: ['', Validators.required],
      agreement: [false, Validators.requiredTrue],
      newsletter: [false],
    });
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    this.recaptchaV3Service
      .execute('submitContact')
      .subscribe((token: string) => {
        this.contactService
          .minimalContact({
            ...this.contactForm.value,
            recaptcha: token,
            options: [this.page],
          })
          .subscribe({
            next: (response) => {
              if (response) {
                this.contactSended = true;
                this.contactForm.reset();
                Object.keys(this.contactForm.controls).forEach((key) => {
                  this.contactForm.get(key)?.setErrors(null);
                });
              }
            },
            error: (error) => {
              this.apiError = error.error.message;
            },
          });
      });
  }
}
