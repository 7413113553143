import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

declare let gtag: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public gaEventEmitter(
    category: string,
    action: string,
    label: string | null = null,
    value: number | null = null
  ): void {
    if (isPlatformBrowser(this.platformId)) {
      gtag('event', 'send', {
        eventCategory: category,
        eventLabel: label,
        eventAction: action,
        eventValue: value,
      });
    }
  }

  getGoogleAnalyticsTrackId(): void {
    if (isPlatformBrowser(this.platformId)) {
      const customGtagScriptEle: HTMLScriptElement =
        document.createElement('script');
      customGtagScriptEle.async = true;
      customGtagScriptEle.src =
        'https://www.googletagmanager.com/gtag/js?id=' + 'G-76EXL6EYXT';
      document.head.prepend(customGtagScriptEle);
      gtag('config', 'G-76EXL6EYXT', { send_page_view: false });
    }
  }
}
