import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HeaderService } from '../services/header.service';
import { Renderer2 } from '@angular/core';
import { filter } from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('header') header!: ElementRef;
  private resizeListener!: () => void;
  isNavbarCollapsed = true;
  isDropdownOpen = false;
  isCollapsed = true;

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
    private headerService: HeaderService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.updateHeaderHeight();
    this.resizeListener = this.renderer.listen('window', 'resize', () => {
      this.updateHeaderHeight();
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const fragment = this.router.url.split('#')[1];
        if (fragment) {
          this.scrollToSection(fragment);
        }
      });
  }

  private updateHeaderHeight() {
    const height = this.header.nativeElement.offsetHeight;
    this.headerService.setHeight(height);
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  public collapseNavbar(): void {
    this.isNavbarCollapsed = true;
  }
  toggleNavbar(): void {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;
  }

  menuButtonClick = (e: { stopPropagation: () => void }) => {
    if (e) {
      e.stopPropagation();
    }
  };

  getMenuIcon(): string {
    return this.isCollapsed
      ? 'assets/images/svg/hamburger.svg'
      : 'assets/images/svg/hamburger-close.svg';
  }

  navigateTo(event: Event, route: string): void {
    event.preventDefault();
    this.router.navigate([route]);
    this.isCollapsed = true;
  }

  goToSection(event: Event, route: string, section: string): void {
    event.preventDefault();
    this.router.navigate([route], { fragment: section }).then(() => {
      this.scrollToSection(section);
    });
    this.isCollapsed = true;
  }

  scrollToSection(sectionId: string) {
    if (isPlatformBrowser(this.platformId)) {
      const element = document.getElementById(sectionId);
      if (element) {
        const yOffset = this.header.nativeElement.offsetHeight;
        const y =
          element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }

  toggleDropdown(state: boolean) {
    this.isDropdownOpen = state;
  }

  ngOnDestroy() {
    if (this.resizeListener) {
      this.resizeListener();
    }
  }
}
