<header>
  <nav #header class="header navbar navbar-expand-lg navbar-light fixed-top">
    <div class="container px-0">
      <a class="navbar-brand" href="/" (click)="navigateTo($event, '/')" class="logo-container">
        <img src="assets/images/svg/logo.svg" alt="siel-logo" class="siel-logo">
      </a>
      <button
        class="navbar-toggler collapsed"
        type="button"
        (click)="toggleCollapse()"
        aria-controls="navbarNav"
        [attr.aria-expanded]="!isCollapsed"
        aria-label="Toggle navigation">
        <img [src]="getMenuIcon()" class="menu-icon" alt="Menu">
      </button>
      <!-- Desktop Navigation -->
      <div *ngIf="isCollapsed" [ngClass]="{'collapse navbar-collapse desktop-nav': true, 'show': !isCollapsed}" id="navbarNav">
        <ul class="navbar-nav d-flex align-items-center">
          <div class="d-flex flex-column">
            <a class="nav-link dropdown-toggle" href="#" id="whatWeDoDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              What we do
            </a>
            <ul class="dropdown-menu" aria-labelledby="whatWeDoDropdown">
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/process-optimization')" href="/process-optimization">PROCESS OPTIMIZATION</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/technology')" href="/technology">TECHNOLOGY</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/people-strategy')" href="/people-strategy">PEOPLE STRATEGY</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/automation-as-a-service')" href="/automation-as-a-service">AutomationX</a></li>
            </ul>
          </div>
          <li class="nav-item mx-1">
            <a class="nav-link" href="/blogs" (click)="navigateTo($event, '/blogs')">What we think</a>
          </li>
          <li class="nav-item mx-1">
            <a class="nav-link" href="/team" (click)="navigateTo($event, '/team')">Why we do it</a>
          </li>
          <button type="button" (click)="navigateTo($event, '/contact')" class="btn btn-lg btn-success ms-md-2">CONTACT US</button>
        </ul>
      </div>
    </div>
    <!-- Full-Screen Mobile Navigation -->
    <div *ngIf="!isCollapsed" [ngClass]="{'collapse navbar-collapse mobile-nav': true, 'show': !isCollapsed}" id="navbarNav">
      <div class="mobile-overlay d-flex flex-column justify-content-between h-100">
        <ul class="navbar-nav d-flex flex-column justify-content-center align-items-center h-100">
          <div class="d-flex flex-column position-relative">
            <a class="nav-link dropdown-toggle" href="#" id="whatWeDoDropdownMobile" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              What we do
            </a>
            <ul class="dropdown-menu dropdown-menu-start" aria-labelledby="whatWeDoDropdownMobile">
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/process-optimization')" href="/process-optimization">PROCESS OPTIMIZATION</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/technology')" href="/technology">TECHNOLOGY</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/people-strategy')" href="/people-strategy">PEOPLE STRATEGY</a></li>
              <li class="text-center"><a class="dropdown-item" (click)="navigateTo($event, '/automation-as-a-service')" href="/automation-as-a-service">AutomationX</a></li>
            </ul>
          </div>
          <li class="nav-item mx-1">
            <a class="nav-link" href="/blogs" (click)="navigateTo($event, '/blogs')">What we think</a>
          </li>
          <li class="nav-item mx-1">
            <a class="nav-link" href="/team" (click)="navigateTo($event, '/team')">Why we do it</a>
          </li>
          <button type="button" (click)="navigateTo($event, '/contact')" class="btn btn-lg btn-success ms-md-2">CONTACT US</button>
        </ul>
        <div class="social-icons">
          <a target="_blank" href="https://x.com/siel_ai">
            <img class="social-icon" src="/assets/images/socials/x.svg" alt="x-logo">
          </a>
          <a target="_blank" class="ms-2" href="https://www.linkedin.com/company/siel-ai/">
            <img class="social-icon" src="/assets/images/socials/li.png" alt="linkedin-logo">
          </a>
        </div>
      </div>
    </div>
  </nav>
</header>
