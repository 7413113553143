<div class="container-fluid mb-2 mb-md-4">
  <section class="d-flex flex-column align-items-center justify-content-center">
    <div class="d-flex w-100 align-items-center justify-content-center">
      <h2 class="parteners-title">Our Partners</h2>
    </div>
    <div class="mt-2 py-2 mt-md-3 w-100">
      <swiper-container init="false" appSwiper [config]="swiperConfig">
        <swiper-slide *ngFor="let brand of brands">
          <div><img [src]="brand.src" [alt]="brand.alt" class="partner-logo"></div>
        </swiper-slide>
      </swiper-container>
    </div>
  </section>
</div>
