<div class="container-fluid fade-down-up">
    <section #section class="card">
        <div class="container py-3 py-md-3 py-lg-4">
          <div class="d-flex flex-column flex-md-row my-2">
            <h1 class="section-title text-whit title">People Strategy</h1>
          </div>
          <div class="d-flex flex-row justify-content-start w-100">
            <div class="purple-line white-line"></div>
          </div>
          <div class="d-flex flex-column flex-md-row align-items-center mt-4">
            <div class="subtitle-container">
              <p class="section-subtitle subtitle text-white">Your company is as valuable as the people who work there. We strive to make your employees happier by implementing AI solutions that take the repetitive, menial tasks off their hands, allowing them to focus on their personal growth and your company’s growth.
              </p>
            </div>
          </div>
          <div class="parent-container position-relative mt-4 my-3 my-lg-5">
            <div class="grid-container">
              <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
                <h3>Talent Management: Strategically attracting and retaining top talent.</h3>
                <p class="text">By making jobs less repetitive, you'll be sure that the top talent in your company stays dedicated and focused on what’s important. This not only enables them to seize any opportunity provided by the workplace, but also ensures the retention of your best employees.</p>
              </div>
              <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
                <h3>Change Management: Navigating and implementing organizational change effectively.              </h3>
                <p class="text">Change is never easy, but we can make it smooth. We provide consultancy to guide your company through the critical phases of adopting and learning new technology, which can lead to new organizational patterns.</p>
              </div>
            </div>
            <div class="card-secondary pt-3 mt-2 pt-lg-5 pb-3 px-0 px-md-3">
              <div *ngFor="let chunk of processesChunks" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
                <div class="d-flex flex-row align-items-center" *ngFor="let lProcess of chunk; let last = last">
                  <p class="text-bold text-center">{{ lProcess.title }}</p>
                  <p *ngIf="!last" class="dot-divider"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="container mt-4 mt-lg-5 mb-3 my-lg-4">
        <div class="d-flex flex-column left-section">
          <h2 class="section-title">Steering Away from the Titanic Syndrome</h2>
          <p class="text-bold">In today's fast-changing business landscape, companies must adapt or risk becoming obsolete. The "Titanic Syndrome" occurs when organizations resist change, holding onto outdated practices that no longer serve their needs, leading them toward failure.</p>

          <h3 class="section-title primary-subtitle pt-3">The Role of a People Strategy</h3>
          <p class="text pt-2">A well-crafted People Strategy helps businesses avoid the Titanic Syndrome by fostering a culture of agility and resilience. By aligning employee growth with technological innovation, companies ensure that their workforce is ready to meet future challenges head-on. Investing in upskilling and reskilling helps teams stay adaptable, turning potential threats into opportunities.</p>

          <h3 class="section-title primary-subtitle pt-2">Leadership and Talent Retention</h3>
          <p class="text pt-2">Strong leadership plays a critical role in navigating change. A People Strategy ensures that leaders are prepared to guide their teams through periods of transformation, promoting a culture that embraces evolution. At the same time, focusing on talent retention keeps top employees engaged, reducing turnover and driving long-term success.</p>

          <h3 class="section-title primary-subtitle pt-2">Building a Future-Proof Workforce</h3>
          <p class="text pt-2">Ultimately, a People Strategy is about more than just managing employees. It's about future-proofing your workforce by ensuring that they are equipped to handle emerging technologies, shifting market demands, and internal organizational changes. This proactive approach helps businesses steer clear of the Titanic Syndrome and continue to thrive in turbulent waters.</p>
        </div>
        <div class="decorative-2">
          <img src="assets/images/svg/ai-3.png" alt="Decorative PNG AI 3">
        </div>
      </section>





  <app-contact-section [page]="'People Strategy'"></app-contact-section>

</div>
