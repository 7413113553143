import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  baseURL = `${environment.apiUrl}/blog`;
  constructor(private http: HttpClient) {}

  getBlogs(limit?: number): Observable<any> {
    const url = limit ? `${this.baseURL}?limit=${limit}` : this.baseURL;
    return this.http.get<any>(`${url}`);
  }

  getBlog(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/${id}`);
  }

  getRelatedBlogs(id: string): Observable<any> {
    return this.http.get<any>(`${this.baseURL}/related/${id}`);
  }

  sendBlogPdf(id: string, email: string): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/send/pdf`, {
      blogId: id,
      email: email,
    });
  }
}
