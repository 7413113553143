export const metadata = {
  newsletter: {
    title: 'Newsletter',
    description: 'SIEL.AI Newsletter',
  },
  home: {
    title: 'Home',
    description: 'SIEL.AI Home',
  },
  processes: {
    title: 'Processes',
    description: 'SIEL.AI Processes',
  },
  aboutUs: {
    title: 'About Us',
    description: 'SIEL.AI About Us',
  },
  contact: {
    title: 'Contact',
    description: 'SIEL.AI Contact',
  },
  privacyPolicy: {
    title: 'Privacy Policy',
    description: 'SIEL.AI Privacy Policy',
  },
  termsAndConditions: {
    title: 'Terms and Conditions',
    description: 'SIEL.AI Terms and Conditions',
  },
  team: {
    title: 'Team',
    description: 'SIEL.AI Team',
  },
  blogs: {
    title: 'Blogs',
    description: 'SIEL.AI Blogs',
  },
  peopleStrategy: {
    title: 'People Strategy',
    description: 'SIEL.AI People Strategy',
  },
  processOptimization: {
    title: 'Process Optimization',
    description: 'SIEL.AI Process Optimization',
  },
  technology: {
    title: 'Technology',
    description: 'SIEL.AI Technology',
  },
  automationasaservice: {
    title: 'AutomationX',
    description: 'SIEL.AI AutomationX',
  },
  dataStrategy: {
    title: 'Data Strategy',
    description: 'SIEL.AI Data Strategy',
  },
};
