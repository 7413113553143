import {
  OnInit,
  AfterViewInit,
  Component,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/services/header.service';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, AfterViewInit {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  constructor(private headerService: HeaderService, private router: Router) {}

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  navigateTo(event: Event, route: string): void {
    event.preventDefault();
    this.router.navigate([route]);
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }
}
