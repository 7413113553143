import { Component } from '@angular/core';
import { SubscriberService } from '../services/subscriber.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  subscribeForm: FormGroup;
  apiError: string | null = null;
  isSubscribed: boolean = false;

  constructor(
    private subscriberService: SubscriberService,
    private fb: FormBuilder
  ) {
    this.subscribeForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  sendSubscribe() {
    if (this.subscribeForm.valid) {
      this.apiError = null;
      this.subscriberService
        .subscribeNewsletter({
          email: this.subscribeForm.value.email,
          subscribedAt: new Date(),
        })
        .subscribe({
          next: (response) => {
            if (response) {
              this.isSubscribed = true;
            }
          },
          error: (error) => {
            this.apiError = error.error.message;
          },
        });
    }
  }
}
