<div class="banner">
  <div class="container">
    <section #section>
      <div class="d-flex flex-row align-items-center h-100 py-3 py-md-0">
        <div class="d-flex flex-column w-50 fade-down-up">
          <h1 class="title">Enhancing</h1>
          <h2 class="sub-title">human potential</h2>
          <div class="mt-2 mt-md-3">
            <button type="button" (click)="navigateTo($event, '/contact')" class="btn btn-lg btn-success btn-discovery">DISCOVER OUR APPROACH</button>
          </div>
        </div>
        <div class="d-flex flex-column w-50 home-image">
          <img src="assets/images/svg/home-1.svg" alt="home-banner">
        </div>
      </div>
    </section>
  </div>
</div>
