<footer class="py-3 py-md-3 footer">
    <div class="container my-1">
        <div class="d-flex flex-row d-none d-md-flex">
          <div class="d-flex flex-column col-9 justify-content-between">
            <div class="d-flex flex-row justify-content-between w-100">
              <div *ngIf="isSubscribed" class="w-100">
                <p class="newsletter-label newsletter-success mb-3">THANK YOU FOR SUBSCRIBING!</p>
              </div>
              <div *ngIf="!isSubscribed" class="w-100">
                <p class="newsletter-label mb-2">Get the latest AI insights and best practices delivered straight to your inbox</p>
                <form [formGroup]="subscribeForm" (ngSubmit)="sendSubscribe()">
                  <div class="newsletter-input">
                      <input type="email" class="form-control" formControlName="email"  placeholder="Email" aria-label="Email">
                      <button class="btn btn-subscribe" type="submit">Subscribe</button>
                  </div>
                </form>
                <div [style.visibility]="subscribeForm.get('email')?.invalid && (subscribeForm.get('email')?.dirty || subscribeForm.get('email')?.touched) ? 'visible': 'hidden'" class="mt-1">
                  <small *ngIf="subscribeForm.get('email')?.errors?.['required']" class="error">Email is required.</small>
                  <small *ngIf="subscribeForm.get('email')?.errors?.['email']" class="error">Invalid email address.</small>
                </div>
                <div *ngIf="apiError" class="mt-1">
                  <small class="error">{{apiError}}</small>
                </div>
                <p class="newsletter-sublabel mt-2">By subscribing, you agree to our Privacy Policy and Terms of Service.</p>
              </div>
            </div>
            <div class="mt-2">
              <a [routerLink]="['/terms-and-conditions']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Terms & Conditions</a>
              <a [routerLink]="['/privacy-policy']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Privacy Policy</a>
              <a [routerLink]="['/support']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Support</a>
              <a [routerLink]="['/contact']" class="text-contact font-weight-light">CONTACT US</a>
            </div>
          </div>
          <div class="d-flex flex-column col-3 align-items-end justify-content-end text-md-end">
            <a class="footer-brand d-none d-md-block" routerLink="['/']">
              <img src="assets/images/svg/footer-logo.svg" alt="siel-logo" class="logo-footer">
            </a>
            <div class="mt-3">
              <a target="_blank" href="https://x.com/siel_ai">
                <img class="social-icon" src="/assets/images/socials/x.svg" alt="x-logo">
              </a>
              <a target="_blank" class="ms-2" href="https://www.linkedin.com/company/siel-ai/">
                <img class="social-icon" src="/assets/images/socials/li.png" alt="linkedin-logo">
              </a>
            </div>
            <p class="text-white fw-bold mt-3 mb-0">2024 &copy; SIEL.AI</p>
          </div>
        </div>
        <div class="d-flex flex-column d-flex d-md-none">
          <div class="d-flex flex-column col-sm-12 justify-content-between">
            <div class="d-flex flex-row justify-content-between w-100">
              <div *ngIf="isSubscribed" class="w-100">
                <p class="newsletter-label newsletter-success mb-3">THANK YOU FOR SUBSCRIBING!</p>
              </div>
              <div *ngIf="!isSubscribed" class="w-100">
                <p class="newsletter-label mb-1">Get the latest AI insights and best practices delivered straight to your inbox</p>
                <form [formGroup]="subscribeForm" (ngSubmit)="sendSubscribe()">
                  <div class="newsletter-input">
                      <input type="email" class="form-control" formControlName="email"  placeholder="Email" aria-label="Email">
                      <button class="btn btn-subscribe" type="submit">Subscribe</button>
                  </div>
                </form>
                <div [style.visibility]="subscribeForm.get('email')?.invalid && (subscribeForm.get('email')?.dirty || subscribeForm.get('email')?.touched) ? 'visible': 'hidden'" class="mt-1">
                  <small *ngIf="subscribeForm.get('email')?.errors?.['required']" class="error">Email is required.</small>
                  <small *ngIf="subscribeForm.get('email')?.errors?.['email']" class="error">Invalid email address.</small>
                </div>
                <div *ngIf="apiError" class="mt-1">
                  <small class="error">{{apiError}}</small>
                </div>
                <p class="newsletter-sublabel mt-1">By subscribing, you agree to our Privacy Policy and Terms of Service.</p>
              </div>
            </div>
            <div class="d-flex flex-row col-12 justify-content-between mt-4">
              <div class="d-flex flex-row">
                <a target="_blank" href="https://x.com/siel_ai" class="social-icon">
                  <img src="/assets/images/socials/x.svg" alt="x-logo">
                </a>
                <a target="_blank" class="social-icon ms-2" href="https://www.linkedin.com/company/siel-ai/">
                  <img src="/assets/images/socials/li.png" alt="linkedin-logo">
                </a>
              </div>
              <a [routerLink]="['/contact']" class="text-contact font-weight-light">CONTACT US</a>
            </div>
            <div class="d-flex flex-row justify-content-between mt-3">
              <a [routerLink]="['/terms-and-conditions']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Terms & Conditions</a>
              <a [routerLink]="['/privacy-policy']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Privacy Policy</a>
              <a [routerLink]="['/support']" class="text-white fw-light me-sm-1 me-md-2 me-lg-3">Support</a>
            </div>
            <div class="d-flex flex-row col-12 justify-content-center">
              <p class="text-white fw-bold mt-3 mb-0">2024 &copy; SIEL.AI</p>
            </div>
          </div>
        </div>
      </div>
</footer>
