<div class="d-flex flex-column h-100">
  <a class="card d-flex" [ngStyle]="{'background-image': 'url(' + (blog.image || 'assets/images/logo.png') + ')'}" style="background-size: cover; background-position: center; background-repeat: no-repeat; width: 100%;" [routerLink]="['/blogs', blog.id]"></a>
  <div class="d-flex flex-column justify-content-between flex-grow-1 mt-2">
    <h3>
      <a [routerLink]="['/blogs', blog.id]" class="card-title">{{ blog.title }}</a>
    </h3>
    <p class="card-abstract">{{ blog.abstract }}</p>
    <div class="mt-auto read">
      <div class="d-flex flex-row align-items-center read-btn" [routerLink]="['/blogs', blog.id]">
        <a class="read-more">READ MORE</a>
        <div class="arrow-icon d-flex flex-row align-items-center justify-content-center">&#x279E;</div>
      </div>
    </div>
  </div>
</div>
