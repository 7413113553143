<div class="container-fluid fade-down-up">
  <section #section class="card">
    <div class="container py-3 py-md-3 py-lg-4">
      <div class="d-flex flex-column flex-md-row my-2">
        <h1 class="section-title text-whit title">Process Optimization</h1>
      </div>
      <div class="d-flex flex-row justify-content-start w-100">
        <div class="purple-line white-line"></div>
      </div>
      <div class="d-flex flex-column flex-md-row align-items-center mt-4">
        <div class="subtitle-container">
          <p class="section-subtitle subtitle text-white">Businesses are complex organisms, each with its own particularities and ways of working. Technology can make your company run like a Swiss clock, and we’re here to help you choose the solutions that will truly enhance efficiency.</p>
        </div>
      </div>
      <div class="parent-container position-relative mt-4 my-3 my-lg-5">
        <div class="grid-container">
          <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
            <h3>What does that mean for you?<br><br></h3>
            <p class="text">By eliminating repetitive and low-value tasks, we help your business unlock greater efficiency and agility. Our non-invasive technology solutions integrate seamlessly with your existing systems, reducing operational bottlenecks without adding complexity. This allows your team to focus on high-impact activities that directly contribute to growth, innovation, and competitive advantage. The result is not only smoother operations but also a more strategic and productive workforce, driving the company toward its long-term goals.</p>
          </div>
          <div class="d-flex flex-column section-card px-2 py-3 justify-content-center justify-content-lg-start p-3 p-lg-4">
            <h3>How can Technology Streamline High-Value Processes?</h3>
            <p class="text">Technology streamlines high-value processes by automating repetitive tasks, enhancing workflow efficiency, and providing actionable insights through data analysis. By integrating seamlessly with your existing systems, it minimizes errors and reduces manual interventions, enabling your team to focus on strategic initiatives that drive business growth. This not only boosts productivity but also improves decision-making and resource allocation, fostering a more agile and innovation-driven organization.</p>
          </div>
        </div>
        <div class="card-secondary pt-3 mt-2 pt-lg-5 pb-3 px-0 px-md-3">
          <div *ngFor="let chunk of processesChunks" class="d-flex flex-row flex-wrap justify-content-center align-items-center">
            <div class="d-flex flex-row align-items-center" *ngFor="let lProcess of chunk; let last = last">
              <p class="text-bold text-center">{{ lProcess.title }}</p>
              <p *ngIf="!last" class="dot-divider"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <section class="container full-mobile-container">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column section-2 px-2 py-4 p-md-4 my-4">
        <div class="d-flex flex-column left-section">

          <h2 class="secondary-title">Our Approach (How We Do It)</h2>
          <p class="text">At <strong>SIEL AI</strong>, we follow a structured and tailored approach to ensure your business reaps the full benefits of <strong>AI</strong> and <strong>RPA</strong> technologies. Our goal is to streamline your operations, enhance efficiency, and drive sustainable growth. Here's how we do it:</p>

          <h3 class="secondary-subtitle">AI Readiness Assessment</h3>
          <p class="text">We begin by evaluating your organization's current state in terms of data, technology, and processes. This comprehensive analysis helps us determine whether your business is ready for AI integration and identifies the gaps that need to be addressed before moving forward.</p>

          <h3 class="secondary-subtitle">Discovery Workshops</h3>
          <p class="text">Through collaborative workshops with your stakeholders, we identify key areas where AI can have the most significant impact. These sessions help us align AI strategies with your business’s unique needs and goals, ensuring the AI implementation is focused on delivering the highest value.</p>

          <h3 class="secondary-subtitle">Customized AI Strategy Development</h3>
          <p class="text">Next, we create a <strong>bespoke AI strategy</strong> tailored to your company’s objectives. This strategy identifies both quick-win opportunities and long-term goals, defining the specific AI technologies that will deliver the most value to your organization.</p>

          <h3 class="secondary-subtitle">AI Implementation Roadmap</h3>
          <p class="text">We develop a detailed roadmap that outlines short-term and long-term milestones for your AI journey. The roadmap offers a clear, step-by-step guide from planning to execution, including pilot projects and full-scale deployment, ensuring that your AI initiatives are executed efficiently and effectively.</p>

          <h3 class="secondary-subtitle">Pilot Projects & Incremental Implementation</h3>
          <p class="text">We believe in starting small but impactful. We implement AI solutions incrementally, beginning with high-impact pilot projects that validate performance. This allows us to refine the solution and ensure alignment with your business processes before scaling the solution company-wide.</p>

          <h3 class="secondary-subtitle">Training & Support</h3>
          <p class="text">Our team provides extensive training to your staff, ensuring that they are well-equipped to work with AI technologies. We offer ongoing support to ensure a smooth transition and maximum effectiveness of your AI initiatives.</p>

          <h3 class="secondary-subtitle">Continuous Monitoring & Optimization</h3>
          <p class="text">AI systems need to adapt over time. We continuously monitor and optimize your AI systems to ensure they evolve with your business needs and continue delivering value, while maximizing ROI.</p>

        </div>
      </div>
    </div>

  </section> -->
  <section class="container">
    <div class="d-flex flex-row mt-2 mt-lg-2">
      <div class="d-flex flex-column py-3 mt-0 mt-lg-4">
        <div class="d-flex flex-column">
          <h2 class="section-title">Our Approach (How We Do It)</h2>
          <p class="text">At <strong>SIEL AI</strong>, we follow a structured and tailored approach to ensure your business reaps the full benefits of <strong>AI</strong> and <strong>RPA</strong> technologies. Our goal is to streamline your operations, enhance efficiency, and drive sustainable growth. Here's how we do it:</p>
          <div class="card-list">
            <process-optimization-services-card  *ngFor="let processOptimizationService of processOptimizationServices" [processOptimizationService]="processOptimizationService"></process-optimization-services-card>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="container processes-list mt-2 mt-md-4 pb-4">
      <h2 class="section-title processes-title">Reinvent your business processes...</h2>
      <p class="text">SIEL AI bridges the gap between technology and business by architecting intelligent automation solutions that address complex challenges. We ensure that your organization moves beyond experimentation with AI to fully scalable, impactful implementations. By aligning advanced automation strategies with clear business outcomes, we help you overcome hurdles like high costs, data quality issues, and workforce transformation, ensuring that your investments deliver tangible results and sustainable growth.</p>
      <div class="d-flex flex-column gap-30 flex-lg-row w-100">
        <div class="d-flex flex-column process-card">
          <div *ngFor="let process of processes | slice: 0:4; let last = last">
            <div class="d-flex flex-row align-items-center gap-30 process">
              <p class="d-flex flex-row align-items-center justify-content-center process-number">{{ process.number }}</p>
              <p class="text">{{ process.text }}</p>
            </div>
            <div class="d-flex flex-row my-2 w-100">
              <div class="purple-line w-75"></div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-column process-card">
          <div *ngFor="let process of processes | slice: 4:7; let last = last">
            <div class="d-flex flex-row align-items-center gap-30 process">
              <p class="d-flex flex-row align-items-center justify-content-center process-number">{{ process.number }}</p>
              <p class="text">{{ process.text }}</p>
            </div>
            <div class="d-flex flex-row my-2 w-100">
              <div class="purple-line w-75"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="decorative-2">
        <img src="assets/images/svg/processes-2.png" alt="Decorative PNG Processes 2">
      </div>
  </section>

  <app-contact-section [page]="'Processes Optimization'"></app-contact-section>
</div>
