<div class="d-flex flex-column h-100">
  <figure class="card d-flex">
       <!--<img
        [src]="processOptimizationService.image || 'assets/images/logo.png'"
        class="img-fluid w-100"
        [alt]="processOptimizationService.title"
        (error)="onImageError($event)"> -->
  </figure> 
   <div class="d-flex flex-column justify-content-between flex-grow-1 mt-2">
    <h2>{{processOptimizationService.title}}</h2>
    <p class="card-abstract">{{ processOptimizationService.abstract }}</p>
    <!-- <div class="mt-auto">
      <div class="d-flex flex-row align-items-center read-btn">
        <div class="arrow-icon d-flex flex-row align-items-center justify-content-center">&#x279E;</div>
      </div>
    </div> -->
  </div> 
</div>
