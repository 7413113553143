import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HeaderService } from '../services/header.service';

@Component({
  selector: 'app-team-page',
  templateUrl: './team-page.component.html',
  styleUrls: ['./team-page.component.scss'],
})
export class TeamPageComponent implements OnInit, AfterViewInit {
  @ViewChild('section') section!: ElementRef;
  @ViewChild('nameColumn') nameColumn!: ElementRef;
  @ViewChild('imageColumn') imageColumn!: ElementRef;
  headerHeight: number = 0;
  constructor(private headerService: HeaderService) {}

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }
}
