import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from '../services/header.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  @ViewChild('section') section!: ElementRef;
  headerHeight: number = 0;
  blogs: any = [];

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.headerService.height$.subscribe((height) => {
      this.headerHeight = height;
      this.updateSectionHeight();
    });
    this.route.data.subscribe((data) => {
      this.blogs = data['blogs'];
    });
  }

  updateSectionHeight() {
    if (this.section) {
      this.section.nativeElement.style.marginTop = `${this.headerHeight}px`;
    }
  }

  ngAfterViewInit() {
    this.updateSectionHeight();
  }
}
